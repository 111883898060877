(function ($, window, document, undefined) {

  'use strict';

  $(function () {

    var clickEventType = ((document.ontouchstart !== null) ? 'click' : 'touchstart');


    $('.gallery .inner').slick({
    	dots: true,
    	infinite: true,
    	speed: 300,
    	slidesToShow: 4,
    	slidesToScroll: 4,
    	rows: 1,
    	lazyLoad: 'progressive',
    	prevArrow: '<div class="slick-prev slick-arrow"><svg role="img" class="prev"><use xlink:href="#icon-arrow-left"></use></svg></div>',
    	nextArrow: '<div class="slick-next slick-arrow"><svg role="img" class="next"><use xlink:href="#icon-arrow-right"></use></svg></div>',

    	responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            rows: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            rows: 2
          }
        }
      ]

    });



    $('.gallery .inner').slickLightbox({
    	itemSelector: 'a',
    	background: 'rgba(255,255,255,.85)',
    	caption: 'caption',
    	navigateByKeyboard  : true,
      imageMaxHeight: 0.8,
      layouts: {
        closeButton: '<button type="button" class="slick-lightbox-close"><svg role="img" class="close"><use xlink:href="#icon-close"></use></svg></button>'
      }
    });





    $('nav a').each(function(){
    	var title = $(this).html();
    	$(this).attr('title',title);
    });



    //ADD ANIMATION CLASSES WHEN ELEM IS IN VIEWPORT
    function prepareElemForRevealAnimation(elem){

      var animationClass = 'animated fadeInUp';

      var waypoint = new Waypoint({
        element: elem,
        handler: function(direction) {
          elem.addClass(animationClass);
        },
        offset: 500
      })
    }



    $('.vh').each(function(index, el) {
      prepareElemForRevealAnimation($(this));
    });




    if($('#map').length){
      GoogleMapsLoader.KEY = 'AIzaSyDW2LHLggegS60pCw-DfurJS0vayiblhpY';
      GoogleMapsLoader.load(function(google) {

          var mapStyles = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];
          var mapType = new google.maps.StyledMapType(mapStyles, {name: 'ms'});
          var mapOptions = {
              zoom: 11,
              zoomControl: false,
              scaleControl: true,
              scrollwheel: false,
              disableDoubleClickZoom: false,
              disableDefaultUI:true,
              mapTypeControl: false,
              center: new google.maps.LatLng(48.918423,  8.76614410),
              mapTypeControlOptions: {
                  mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'ms']
              }
          };

          var map = new google.maps.Map(document.getElementById('map'),
              mapOptions);
          map.mapTypes.set('ms', mapType);
          map.setMapTypeId('ms');


          var myLatLng = {lat: 48.918423, lng:  8.76614410};
          var mapIcon = 'assets/img/mapicon.svg';
          var marker = new google.maps.Marker({
              position: myLatLng,
              map: map,
              clickable: false,
              icon: new google.maps.MarkerImage(mapIcon,null, null, null, new google.maps.Size(26,46)),
              optimized: false
          });
          
      });
    }




    $('.showNav').on(clickEventType,function(e) {
      $('body').toggleClass('navOpen');
    })



  });

})(jQuery, window, document);









